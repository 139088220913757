/**
 * Primary Menu
 * -----------------------------------------------------------------------------
 *
 * Behaviors for elements the typically appear in the `.menu` element which
 * contains the primary navigation for the site.
 *
 * @todo Refine this whole script.
 * @todo Determine if we should apply `.menu` scoping to the rest of the script
 *       like we did for `setMenuLinkToggleTabIndex()`.
 */

// Imports
const $              = require( 'jquery' );
const debounce       = require( '../utilities/_debounce' );
const { MediaQuery } = require( '../utilities/_media-query' );



$(() => {

  /**
   * Set `tabindex` on Primary Navigation items that have a dropdown menu.
   *
   * This prevents the dropdown's caret from receiving focus above the medium
   * breakpoint as the dropdown will display once the dropdown link receives
   * focus.
   *
   * Notes
   * 1. `.menu` added to selector so that the use of `.menu__link--toggle` outside
   *    of `.menu` for the Alumni site is not affected by the conditional value of
   *    `tabindex` on said element.
   */
  function setMenuLinkToggleTabIndex() {

    MediaQuery._init();

    const tabIndex = MediaQuery.atLeast( 'md' ) ? -1 : 0;

    /* 1 */
    $( '.menu .menu__link--toggle' ).attr( 'tabindex', tabIndex );

  }

  $( window ).on( 'load resize', debounce( setMenuLinkToggleTabIndex, 250 ) );



  let prevent = true;
  let enable;
  let close;

  const click = function(e) {
    if (prevent || !this.parentNode.classList.contains('menu__item--active')) {
      over.call(this.parentNode);
      e.preventDefault();
    }
    else {
      out();
    }
  };

  const targets = document.querySelectorAll('.menu__link--toggle');

  for (let i = 0; i < targets.length; i += 1) {
    targets[i].addEventListener('click', click);
  }


  const items = document.querySelectorAll('.menu__item');

  const reset = function() {
    prevent = true;
    clearTimeout(enable);
    clearTimeout(close);
    for (let i = 0; i < items.length; i += 1) {
      items[i].classList.remove('menu__item--active');
    }
    document.body.removeEventListener('click', outClick);
    // document.removeEventListener('scroll', reset);
  };

  var over = function(e) {
    reset();
    this.classList.add('menu__item--active');
    enable = setTimeout(function() {
      prevent = false;
    }, 100);
    document.body.addEventListener('click', outClick);
    // document.addEventListener('scroll', reset);
  };

  var out = function(e) {
    close = setTimeout(reset, 300);
  };

  var outClick = function(e) {
    var el = document.querySelector('.menu__item--active');
    if (!el.contains(e.target)) {
      out();
    }
  };

});
