/**
 * Quick Escape (Exit)
 * -----------------------------------------------------------------------------
 *
 * Used to exit sites/pages that contain sensitive information, such as family
 * violence, that an individual worried about abuse can quickly hide from a
 * potential abuser.
 *
 * This button should:
 * 1. Change the location (URL) of the current window.
 * 2. Open a new window - to hide the current window while it changes.
 * 3. Work with the escape key.
 *
 * To configure the Current Window and New Window URLs outside of the core code,
 * define the following global (window) object before this code runs:
 *
 * @type {Object}
 *
 * @property {String} current-window-url Absolute URL to change the current
 *                                       window/tab to.
 * @property {String} new-window-url     Absolute URL to set the new window/tab
 *                                       to.
 *
 * const UND_QUICK_ESCAPE = {
 *   'current-window-url': '',
 *   'new-window-url':     '',
 * };
 */



// Imports.
const $ = require( 'jquery' );



function QuickEscape() {

  // Globals.
  const NAMESPACE = '.und.quick-escape-button';
  const $btn      = $( '#quick-escape-button' );
  const defaults  = {
    'current-window-url': 'https://weather.com',
    'new-window-url':     'https://google.com'
  };
  const config = Object.assign( {}, defaults, window.UND_QUICK_ESCAPE || {} );



  /**
   * Add event handlers.
   */
  function events() {

    $btn.on( `click${NAMESPACE}`, exitSite );
    $( document ).on( 'esc.und' , exitSite );

  }

  /**
   * Set URLs for current and new windows.
   *
   * Notes:
   * 1. The second parameter for `open()` is explicitly not set. When a name is
   *    defined for the new window/tab, and that window is then used to navigate
   *    back to the page that spawned the new window/tab, subsequent new
   *    windows/tabs WILL NOT be spawned as the context/name currently exists.
   *    If defining a name is every required, ensure that each new window/tab
   *    has a unique name, i.e. append a timestamp or generate a UUID.
   */
  function exitSite() {

    window.open( config[ 'new-window-url' ] ); // #1
    window.location.replace( config[ 'current-window-url' ] );

  }



  // Setup Quick Escape ------------------------------------------------------ /

  // Bail, button does not exist or is not enabled (visible).
  if ( !$btn.length || !$btn.is( ':visible' ) ) {
    return;
  }

  events();

}



QuickEscape();
