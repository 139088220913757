/**
 * Open/Close All Accordions
 * -----------------------------------------------------------------------------
 *
 * Requires the accordion group trigger element to be within an accordion group.
 */

// Imports.
const $             = require( 'jquery' );
const { Accordion } = require( './u-accordion' );



class AccordionGroupTrigger {

  constructor() {
    this._events();
  }

  /**
   * Apply event handlers.
   *
   * Events should be namespaced for easy removal.
   *
   * @private
   */
  _events() {

    $( document )
      .on( `click${AccordionGroupTrigger.NAMESPACE}` , Accordion.Selector.ACCORDION,                          this._onAccordionClick.bind( this )            )
      .on( `click${AccordionGroupTrigger.NAMESPACE}` , AccordionGroupTrigger.Selector.ACCCORDION_GROUP_TOGGLE, this._onAccordionGroupTriggerClick.bind( this ) )
      .on( `ctrl-f${AccordionGroupTrigger.NAMESPACE}`, this._onFindInPage.bind( this ) );

  }

  /**
   * Get various components of an accordion group.
   *
   * @private
   * @param  {DOMNode} el Any element within an accordion group.
   * @return {Object}     Each property is a jQuery instance and may contain one
   *                      or more DOM references.
   */
  _getAccordionComponents( el ) {

    const $group      = $( el ).closest( Accordion.Selector.ACCORDION_GROUP );
    const $toggle     = $group.find( AccordionGroupTrigger.Selector.ACCCORDION_GROUP_TOGGLE );
    const $accordions = $group.find( Accordion.Selector.ACCORDION );
    const $closed     = $accordions.filter( `:not(${Accordion.Selector.ACCORDION_ACTIVE})` )

    return { $toggle, $group, $accordions, $closed };

  }

  /**
   * Toggle the accordion group toggle.
   *
   * Updates text and aria attributes accordingly.
   *
   * @private
   * @param {Boolean} allOpened If the state of the toggle should be `open`.
   *                            The state of the toggle should be considered
   *                            `open` when all accordions in an accordion group
   *                            will be open. Otherwise, the state should not be
   *                            considered `open`.
   */
  _toggleAccordionGroupTrigger( $toggle, allOpened = true ) {

    const find    = allOpened ? 'Open'  : 'Close';
    const replace = allOpened ? 'Close' : 'Open';
    const html    = $toggle
                      .html()
                      .replace( find, replace );

    $toggle
      .html( html )
      .attr( 'aria-expanded', allOpened.toString() );

  }

  /**
   * On Accordion Group Toggle Click
   *
   * Change toggle text and determine which accordions to click.
   *
   * Notes
   *
   * 1. Handler maybe be initiated by other means and a relevant target element
   *    and thus relevant `$toggle` element may not be available, i.e. the
   *    custom event `ctrl-f.und`.
   *
   * @private
   * @param {Event} e The browser event.
   */
  _onAccordionGroupTriggerClick( e ) {

    const {
      $accordions,
      $closed,
      $group,
      $toggle
    }                       = this._getAccordionComponents( e.target );
    const openAllAccordions = 'false' === $toggle.attr( 'aria-expanded' );
    const customEvent       = AccordionGroupTrigger.Event[ openAllAccordions ? 'OPENED' : 'CLOSED' ];
    const $toTrigger        = ( $closed.length ? $closed : $accordions ).find( Accordion.Selector.ACCORDION_TRIGGER );

    if ( $toggle.length ) { /* 1 */

      $toTrigger.trigger( 'click', [ true ] );
      this._toggleAccordionGroupTrigger( $toggle, openAllAccordions );
      $toggle.trigger( customEvent, [ $toggle, $accordions ] );

    }

  }

  /**
   * On Accordion Click
   *
   * Observe accordion clicks within a group so that the accordion group toggle
   * can respond accordingly.
   *
   * Notes
   *
   * 1. Only required until all legacy tabs are updated. Bridge code does not
   *    add an Accordion Group Toggle.
   *
   * @private
   * @param {Event} e The browser event.
   */
  _onAccordionClick( e ) {

    const { $toggle, $closed } = this._getAccordionComponents( e.target );

    if ( $toggle.length ) { /* 1 */
      this._toggleAccordionGroupTrigger( $toggle, 0 === $closed.length );
    }

  }

  /**
   * On Find-in-Page Event.
   *
   * Open any un-opened accordions if user performs "Find in Page" search.
   *
   * @private
   * @param {Event} e The browser event.
   */
  _onFindInPage( e ) {

    $( `${AccordionGroupTrigger.Selector.ACCCORDION_GROUP_TOGGLE}[aria-expanded="false"]` )
      .trigger( 'click', [ true ] );

  }

  /**
   * Remove event handlers and references.
   *
   * @todo ¿Close all accordions?
   * @public
   */
  destroy() {

    $( document ).off( AccordionGroupTrigger.NAMESPACE );

  }

  /**
   * Initialize a AccordionGroupTrigger instance on the current element.
   *
   * @public
   */
  static init() {

    const $doc = $( document );

    $doc.data( AccordionGroupTrigger.DATA_KEY, new AccordionGroupTrigger( $doc ) );

  }

}

/**
 * Property name for plugin instance store with jQuery `data()`.
 *
 * @type {String}
 */
AccordionGroupTrigger.DATA_KEY  = 'und.accordion-group-trigger';

/**
 * Event name space for plugin.
 *
 * @type {String}
 */
AccordionGroupTrigger.NAMESPACE = `.${AccordionGroupTrigger.DATA_KEY}`;

/**
 * Custom Events
 *
 * @type {Object}
 */
AccordionGroupTrigger.Event = {
  OPENED: `opened${AccordionGroupTrigger.NAMESPACE}`,
  CLOSED: `closed${AccordionGroupTrigger.NAMESPACE}`
};

/**
 * Plugin CSS Selector values.
 *
 * @type {Object}
 */
AccordionGroupTrigger.Selector = {
  ACCCORDION_GROUP_TOGGLE: '[data-und-toggle="accordion-group-trigger"]'
};



/**
 * Implementation
 * -----------------------------------------------------------------------------
 */

AccordionGroupTrigger.init();


module.exports = { AccordionGroupTrigger };
