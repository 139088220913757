/**
 * Events
 * -----------------------------------------------------------------------------
 *
 * Expose custom events with a friendly, namespaced, name that multiple
 * components may be interested in, saving those components from
 * re-implementing conditionals etc. that is required for those events.
 */

const $doc = $( document );



/**
 * Keydown Events
 *
 *
 * @param {Event} e The browser event.
 */
$doc.on( 'keydown', function ( e ) {

  /**
   * On CTRL + F or Command + F.
   *
   * Original use/intent: Trigger event when user is searching the page.
   */
  if ( ( e.ctrlKey || e.metaKey ) && 'f' === e.key ) {
    $doc.trigger( 'ctrl-f.und' );
  }

  /**
   * On Escape (Esc).
   */
  if ( 'Escape' === e.key || 'Esc' === e.key ) {
    $doc.trigger( 'esc.und' );
  }

} );
