/**
 * Responsive Tabs
 * -----------------------------------------------------------------------------
 *
 * Tabs become accordions when the viewport is below the small breakpoint,
 * 768px.
 */

// Imports.
const $                         = require( 'jquery' );
const { MediaQuery }            = require( '../utilities/_media-query' );
const { Accordion }             = require( './u-accordion' );
const { AccordionGroupTrigger } = require( './u-accordion-group-trigger' );
const animateTo                 = require( '../utilities/_animate-to' );



class ResponsiveTabs {

  constructor( $el, options = {} ) {

    this.$el        = $el; // `.tabs`
    this.$tabs      = this.$el.find( `> ${ResponsiveTabs.Selector.TABS_NAV_LIST} > ${ResponsiveTabs.Selector.TABS_NAV_ITEM}` );
    this.$tabPanels = this.$el.find( `> ${ResponsiveTabs.Selector.TABS_PANELS} > ${ResponsiveTabs.Selector.TABS_PANEL}` );
    this.options    = Object.assign( {}, ResponsiveTabs.Defaults, options );
    this.firstDraw  = true;
    this.state      = 'TAB';

    MediaQuery._init();
    this._events();
    this._checkMediaQuery(); // Initialize as Tab/Accordion depending on viewport size on page load.

    if ( this.options.autoFocus ) {
      this._autoFocus();
    }

  }

  /**
   * Scroll to the tab/accordion defined by the URL hash.
   *
   * @private
   */
  _autoFocus() {

    const $root = this[ 'ACCORDION' === this.currentState ? '$tabPanels' : '$tabs' ];
    const id    = window.location.hash.replace( /^#/, '' );

    animateTo( $root.find( `> [data-id="${id}"]` )[ 0 ] );

  }

  /**
   * Global events to respond to.
   *
   * @private
   */
  _events() {

    this.mediaQueryHandler = this._checkMediaQuery.bind( this );

    $( window ).on( 'changed.und.mq', this.mediaQueryHandler );

  }

  /**
   * Apply ResponsiveTabs click handler to tab.
   *
   * Namespaced for easy removal.
   *
   * @private
   */
  _onTabClickHandler() {

    const _this = this;

    // Delegated handler.
    this.$el.on( ResponsiveTabs.Event.CLICK, ResponsiveTabs.Selector.TABS_NAV_ITEM, function ( e ) {

      e.stopPropagation();

      const $target = $( this );

      // Already active, bail.
      if ( $target.hasClass( ResponsiveTabs.ClassName.TABS_NAV_ITEM_ACTIVE ) ) {
        return;
      }

      _this._switchTab( $target );

    } );

  }

  /**
   * Apply ResponsiveTabs click handler to tab.
   *
   * Namespaced for easy removal.
   *
   * Notes
   *
   * 1. Second value in array is IE/Edge specific value.
   * 2. Not using `eq()` because we do not want -1 to return the last tab. -1
   *    should fail as an index for a tab.
   *
   * @private
   */
  _onTabKeyDownHandler() {

    const _this = this;

    // Delegated handler.
    this.$el.on( ResponsiveTabs.Event.KEYDOWN, ResponsiveTabs.Selector.TABS_NAV_ITEM, function ( e ) {

      const $target        = $( this );
      const activeTabIndex = _this.$tabs.index( $target );
      const nextTabIndex   = -1 !== [ 'ArrowLeft',  'Left'  ].indexOf( e.key ) ? activeTabIndex + -1 /* 1 */
                           : -1 !== [ 'ArrowRight', 'Right' ].indexOf( e.key ) ? activeTabIndex + 1  /* 1 */
                           : null;
      const $nextTarget    = _this.$tabs[ nextTabIndex ] ? $( _this.$tabs[ nextTabIndex ] ) : false; /* 2 */

      if ( $nextTarget && $nextTarget.length ) {

        e.preventDefault();
        _this._switchTab( $nextTarget );

      }

    } );

  }

  /**
   * Remove ResponsiveTabs event handlers.
   *
   * @private
   */
  _removeHandlers() {
    this.$el.off( ResponsiveTabs.NAMESPACE );
  }

  /**
   * Close a tab.
   *
   * Removes active class from active tab and active tab panel.
   *
   * @private
   */
  _closeTab() {

    const $closedTab   = this.$tabs.filter( ResponsiveTabs.Selector.TABS_NAV_ITEM_ACTIVE );
    const $closedPanel = this.$tabPanels.filter( ResponsiveTabs.Selector.TABS_PANEL_ACTIVE );

    $closedTab.removeClass( ResponsiveTabs.ClassName.TABS_NAV_ITEM_ACTIVE );
    $closedPanel.removeClass( ResponsiveTabs.ClassName.TABS_PANEL_ACTIVE );

    this.$tabs
      .find( `> ${ResponsiveTabs.Selector.TABS_NAV_TRIGGER}` )
      .attr( {
        'aria-selected': false,
        'tabindex':      -1
      } );

    $closedTab.trigger( ResponsiveTabs.Event.CLOSED, [ $closedTab, $closedPanel ] ); /* 3 */

  }

  /**
   * Open a tab.
   *
   * Notes
   *
   * 1. Constructing the URL with individual values from the `location` object
   *    prevents hash compounding (#alpha#beta#charlie).
   * 2. History API is used to update the URL so we don't create unwanted
   *    history when opening tabs - i.e. user has to back through a bunch of tab
   *    locations before finally going back to the previous page.
   *
   * @private
   * @param {jQuery} $target Tab container element, `li`.
   */
  _openTab( $target ) {

    const $btn = $target.find( `> ${ResponsiveTabs.Selector.TABS_NAV_TRIGGER}` );
    const id   = $btn.data( 'id' );
    const l    = window.location;
    const url  = `${l.origin}${l.pathname}${l.search}#${id}`; /* 1 */
    let   $targetPanel;

    $target.addClass( ResponsiveTabs.ClassName.TABS_NAV_ITEM_ACTIVE );

    $btn
      .attr( {
        'aria-selected': true,
        'tabindex':      null
      } )
      .focus();

    $targetPanel = this.$tabPanels
      .filter( `#tab-panel-${id}` )
      .first()
      .addClass( ResponsiveTabs.ClassName.TABS_PANEL_ACTIVE );

    $target.trigger( ResponsiveTabs.Event.OPENED, [ $target, $targetPanel ] ); /* 3 */

    history.replaceState( {}, '', url ); /* 2 */

  }

  /**
   * Switch Tab
   *
   * @private
   * @param {jQuery} $target Tab container element, `li`.
   */
  _switchTab( $target ) {

    this._closeTab();
    this._openTab( $target );

  }

  /**
   * Set ResponsiveTabs state.
   *
   * State is determined by checking the breakpoint when Tab/Accordion
   * transitions.
   *
   * @private
   */
  _checkMediaQuery() {

    let newState  = MediaQuery.atLeast( 'sm' ) ? 'TAB' : 'ACCORDION';
    let prevState = this.currentState;

    if ( newState !== prevState ) {

      this._handleMarkupChanges( newState );

      this.currentState = newState;

      if ( 'ACCORDION' === newState ) {
        this._removeHandlers();
      } else {
        this._onTabClickHandler();
        this._onTabKeyDownHandler();
      }

    }

  }

  /**
   * Handle markup changes between Tabs and Accordion states.
   *
   * Transitions (Active Tab/Accordion Behavior)
   * -------------------------------------------
   * - No URL Hash: First tab is selected, no scrolling. Accordion is selected.
   * - w/URL Hash: Hash will determine the active tab and scrolled to - only on
   *   initial page render, will be ignored after.
   * -
   * -
   *
   * Notes
   *
   * 1. When URL contains fragment identifier (hash), the hash will determine
   *    the active tab and scroll to it. This ONLY happens on initial page
   *    load and will be ignored if hash is updated WITHOUT page re-load.
   * 2. First tab is selected when no fragment identifier (hash) is present in
   *    the URL. No Accordions will be selected.
   * 3. -1 occurs on page load when there's no hash. On page load, DO NOT make
   *    first accordion active by setting index to `0`, so set to `null`.
   * 4. Update parent container.
   * 5. Update CSS classes on and within the `.tabs__panel` element between
   *    Tabs/Accordion.
   * 6. Tabs to Accordions: As only one tab can be active at any given time, the
   *    active tab will be the active accordion.
   * 7. Accordions to Tabs: The first active accordion will be the active tab.
   * 8. Add/Remove Accordion plugin instance.
   * 9. Update tabs specific markup that does not overlap with accordion markup,
   *    primarily the tabs navlist.
   * 10. Manually switch the AccordionGroupTrigger back to its initial state
   *     without using a `click` event. Doing so would prevent the first
   *     first selected accordion from becoming the related selected tab, thus
   *     forcing the first tab to always be selected after mutation.
   *
   * @private
   * @param {String} toState The desired ResponsiveTabs state. Will be
   */
  _handleMarkupChanges( toState ) {

    let fromState = 'ACCORDION';

    if ( 'ACCORDION' === toState ) {
      fromState = 'TAB';
    }

    const fromClasses = ResponsiveTabs.MarkupChangeLookups[ fromState ];
    const toClasses   = ResponsiveTabs.MarkupChangeLookups[ toState ];
    const hash        = window.location.hash;
    const $root       = this[ 'ACCORDION' === fromState ? '$tabs' : '$tabPanels' ];
    let   activeIndex;

    /* 1 */
    if ( hash && this.firstDraw ) {
      activeIndex = $root.find( 'button' ).index( $root.find( `button[data-id="${hash.substring(1)}"]` ) );

    /* 2 */
    } else {
      activeIndex = this.$tabPanels.index( this.$tabPanels.filter( fromClasses.active.selector ) );
    }

    /* 3 */
    if ( -1 === activeIndex ) {
      activeIndex = 'ACCORDION' === toState ? null : 0;
    }

    /* 4 */
    this.$el
      .removeClass( fromClasses.container.className )
      .addClass( toClasses.container.className );

    /* 5, 6, 7 */
    this.$tabPanels.each( function ( i, panel ) {

      const isActiveIndex = i === activeIndex;
      const activeClass   = isActiveIndex ? ` ${toClasses.active.className}` : '';
      const $panel        = $( panel );
      const plugin        = $panel.data( Accordion.DATA_KEY );

      $panel
        .removeClass( `${fromClasses.target.className} ${fromClasses.active.className} ${toClasses.active.className}` )
        .addClass( `${toClasses.target.className} ${activeClass}` )
        .find( `> ${Accordion.Selector.ACCORDION_HEADING} ${Accordion.Selector.ACCORDION_TRIGGER}` )
          .attr( 'aria-expanded', isActiveIndex )
          .attr( 'aria-selected', isActiveIndex )
            .find( Accordion.Selector.ACCORDION_TRIGGER_TEXT )
            .text( isActiveIndex ? 'Close' : 'Open' )
          .end()
        .end()
        .find( `> ${fromClasses.content.selector}` )
          .removeClass( fromClasses.content.className )
          .addClass( toClasses.content.className );

      /* 8 */
      if ( 'ACCORDION' === toState && !plugin ) {
        $panel.data( Accordion.DATA_KEY, new Accordion( $panel, { autoFocus: false } ) );
      }

      /* 8 */
      if ( 'TAB' === toState && plugin ) {

        plugin.destroy();
        $panel.data( Accordion.DATA_KEY, null );

      }

    } );

    /* 9 */
    if ( 'TAB' === toState ) {

      const AccordionGroupTriggerInstance = $( document ).data( AccordionGroupTrigger.DATA_KEY );

      this.$tabs
        .removeClass( ResponsiveTabs.ClassName.TABS_NAV_ITEM_ACTIVE )
        .eq( activeIndex )
          .addClass( ResponsiveTabs.ClassName.TABS_NAV_ITEM_ACTIVE )
        .end()
          .find( ResponsiveTabs.Selector.TABS_NAV_TRIGGER )
            .attr( {
              'aria-selected': false,
              'tabindex':      -1
            } )
            .eq( activeIndex )
              .attr( {
                'aria-selected': true,
                'tabindex':      null
              } );

      /* 10 */
      this.$el
        .find( `> ${AccordionGroupTrigger.Selector.ACCCORDION_GROUP_TOGGLE}[aria-expanded="true"]` )
          .each( ( i, el ) => AccordionGroupTriggerInstance._toggleAccordionGroupTrigger( $( el ), false ) );

    }

    if ( this.firstDraw ) {
      this.firstDraw = false;
    }

    this.state = toState;

    this.$el.trigger( ResponsiveTabs.Event.MUTATION, [ this.$el, fromState, toState ] );

  }

  /**
   * Remove event handlers and references.
   *
   * Notes
   *
   * - ¿Set to first tab when destroyed?
   *
   * @public
   */
  destroy() {

    this._removeHandlers();
    $( window ).off( 'changed.und.mq', this.mediaQueryHandler );

    this.$tabPanels.each( function ( i, panel ) {

      const AccordionInstance = $( panel ).data( Accordion.DATA_KEY );

      if ( AccordionInstance ) {
        AccordionInstance.destroy();
      }

    } );

  }

  /**
   * Initialize a ResponsiveTabs instance on the current element.
   *
   * @public
   * @param {jQuery} $collection jQuery collection of each set of tabs on the
   *                             page.
   */
  static init( $collection ) {

    $collection.each( function () {

      const $el = $( this );

      $el.data( ResponsiveTabs.DATA_KEY, new ResponsiveTabs( $el ) );

    } );

  }

}



/**
 * Static Properties
 * -----------------------------------------------------------------------------
 */

/**
 * Default plugin options.
 *
 * @type {Object}
 */
ResponsiveTabs.Defaults = {

  /**
   * Scoll to tab defined by URL hash.
   *
   * @type {Boolean}
   */
  autoFocus: true
};

/**
 * Property name for plugin instance store with jQuery `data()`.
 *
 * @type {String}
 */
ResponsiveTabs.DATA_KEY = 'und.responsive-tabs';

/**
 * Event name space for plugin.
 *
 * @type {String}
 */
ResponsiveTabs.NAMESPACE = `.${ResponsiveTabs.DATA_KEY}`;

/**
 * Custom Events
 *
 * @type {Object}
 */
ResponsiveTabs.Event = {
  CLICK:    `click${ResponsiveTabs.NAMESPACE}`,
  CLOSED:   `closed${ResponsiveTabs.NAMESPACE}`,
  KEYDOWN:  `keydown${ResponsiveTabs.NAMESPACE}`,
  OPENED:   `opened${ResponsiveTabs.NAMESPACE}`,
  MUTATION: `mutated${ResponsiveTabs.NAMESPACE}`
};

/**
 * Plugin class names.
 *
 * Notes
 *
 * 1. Container element for a set of tabs.
 * 2. TABS_TRIGGER container element.
 * 3. Element that has `data-id`.
 * 4. Element that TABS_TRIGGER points to via `data-id`.
 * 5. Container element for the content of TABS_PANEL. TABS_PANEL_CONENT cannot
 *    be the same element as TABS_PANEL due to the fact that our tabs are
 *    responsive (convert to accordions on small viewports). It also
 *    provides a clean separation between TABS_HEADING for styling purposes
 *    related to how responsive tabs behave.
 * 6. Used on small viewports to transition a tab to an accordion. Will contain
 *    Accordion.ClassName.ACCORDION_TRIGGER.
 *
 * @type {Object}
 */
ResponsiveTabs.ClassName = {
  TABS:                 'tabs',                   /* 1 */
  TABS_NAV_LIST:        'tabs__nav-list',
  TABS_NAV_ITEM:        'tabs__nav-item',         /* 2 */
  TABS_NAV_ITEM_ACTIVE: 'tabs__nav-item--active',
  TABS_NAV_TRIGGER:     'tabs__nav-trigger',      /* 3 */
  TABS_PANELS:          'tabs__panels',
  TABS_PANEL:           'tabs__panel',            /* 4 */
  TABS_PANEL_ACTIVE:    'tabs__panel--active',
  TABS_PANEL_CONTENT:   'tabs__panel-content',    /* 5 */
  TABS_HEADING:         'tabs__heading'           /* 6 */
};

/**
 * Plugin CSS Selector values.
 *
 * Based off of `ClassName` values.
 *
 * @type {Object}
 */
ResponsiveTabs.Selector = {};

Object
  .keys( ResponsiveTabs.ClassName )
  .map( ( key, value ) => ResponsiveTabs.Selector[ key ] = `.${ResponsiveTabs.ClassName[ key ]}` );


/**
 * Dictionary that maps a tab class/selector to its respective accordion class
 * (and visa versa).
 *
 * @type {Object}
 */
ResponsiveTabs.MarkupChangeLookups = {

  'ACCORDION': {
    'container': {
      className: Accordion.ClassName.ACCORDION_GROUP
    },
    'target': {
      className: Accordion.ClassName.ACCORDION
    },
    'heading': {
      className: Accordion.ClassName.ACCORDION_HEADING,
      selector:  Accordion.Selector.ACCORDION_HEADING
    },
    'trigger': {
      className: Accordion.ClassName.ACCORDION_TRIGGER,
      selector:  Accordion.Selector.ACCORDION_TRIGGER
    },
    'content': {
      className: Accordion.ClassName.ACCORDION_PANEL,
      selector:  Accordion.Selector.ACCORDION_PANEL
    },
    'active': {
      className: Accordion.ClassName.ACCORDION_ACTIVE,
      selector:  Accordion.Selector.ACCORDION_ACTIVE
    }
  },

  'TAB': {
    'container': {
      className: ResponsiveTabs.ClassName.TABS
    },
    'target': {
      className: ResponsiveTabs.ClassName.TABS_PANEL
    },
    'heading': {
      className: ResponsiveTabs.ClassName.TABS_HEADING,
      selector:  ResponsiveTabs.Selector.TABS_HEADING
    },
    'trigger': {
      className: Accordion.ClassName.ACCORDION_TRIGGER,
      selector:  Accordion.Selector.ACCORDION_TRIGGER
    },
    'content': {
      className: ResponsiveTabs.ClassName.TABS_PANEL_CONTENT,
      selector:  ResponsiveTabs.Selector.TABS_PANEL_CONTENT
    },
    'active': {
      className: ResponsiveTabs.ClassName.TABS_PANEL_ACTIVE,
      selector:  ResponsiveTabs.Selector.TABS_PANEL_ACTIVE
    }
  }

};



/**
 * Implementation
 * -----------------------------------------------------------------------------
 */

ResponsiveTabs.init( $( ResponsiveTabs.Selector.TABS ) );



module.exports = { ResponsiveTabs };
