/**
 * Accordions
 * -----------------------------------------------------------------------------
 *
 * Each accordion behaves independently, meaning that opening one accordion does
 * not close all other accordions in the same accordion group.
 *
 * Emits custom events. See `Accordion.Event` below for list of events.
 */

// Imports.
const $         = require( 'jquery' );
const animateTo = require( '../utilities/_animate-to' );



class Accordion {

  constructor( $el, options = {} ) {

    this.$el      = $el; // .accordion
    this.$trigger = this.$el.find( `> ${Accordion.Selector.ACCORDION_HEADING} > ${Accordion.Selector.ACCORDION_TRIGGER}` );
    this.options  = Object.assign( {}, Accordion.Defaults, options );

    this._events();

    if ( this.options.autoFocus ) {
      this._autoFocus( window.location.hash );
    }

  }

  /**
   * Scroll to the tab/accordion defined by the URL hash.
   */
  _autoFocus( hash ) {

    if ( hash ) {

      const _this = this;
      const id    = `#${this.$trigger.data( 'id' )}`;

      // Hash provided, but it's not this accordion. Bail.
      if ( hash !== id ) {
        return;
      }
      animateTo(
          this.$el[ 0 ],                                // target element
          0,                                            // offset
          400,                                          // duration
          true,                                         // measure headers
          500,                                          // delay
          () => _this.$trigger.trigger( 'click', true ) // on animation complete
        );

    }

  }

  /**
   * Apply event handlers.
   *
   * Notes
   *
   * 1. Events should be namespaced for easy removal.
   */
  _events() {
    this.$trigger.on( `click${Accordion.NAMESPACE}`, this._toggleAccordion.bind( this ) ); /* 1 */
  }

  /**
   * Open/Close an Accordion.
   *
   * Accordion ID is only added to the URL on initial accordion open. Closing
   * ANY accordion will remove an accordion ID from the URL.
   *
   * Constructing the URL with individual values from the `location` object
   * prevents hash compounding (#alpha#beta#charlie) and allows for removal of
   * the hash.
   *
   * Notes
   *
   * 1. `isActive` will represent the state of the element prior to being
   *    clicked. For `aria-expanded` and other items, we need to know the state
   *    of the accordion post click post, so we inverse the value of `isActive`
   *    to those items.
   * 2. History API is used so that we don't create unwanted history when
   *    opening tabs then using browser back button.
   * 3. Only update URL if the user triggered the click and not code.
   * 4. Custom, namespaced event that other components and code can respond to.
   *
   * @param {Event} e The browser event.
   */
  _toggleAccordion( e, isProgrammaticalClick = false ) {

    const isActive     = this.$el.hasClass( Accordion.ClassName.ACCORDION_ACTIVE ); /* 1 */
    const willBeActive = !isActive;
    const id           = this.$trigger.data( 'id' );
    const l            = window.location;
    const url          = `${l.origin}${l.pathname}${l.search}${ willBeActive ? `#${id}` : ''}`;
    const customEvent  = Accordion.Event[ willBeActive ? 'OPENED' : 'CLOSED' ];
    let   $content;

    this.$trigger
      .attr( 'aria-expanded', willBeActive )
      .attr( 'aria-selected', willBeActive )
      .find( Accordion.Selector.ACCORDION_TRIGGER_TEXT )
        .text( willBeActive ? 'Close' : 'Open' );
    this.$el.toggleClass( Accordion.ClassName.ACCORDION_ACTIVE, willBeActive );

    if ( willBeActive ) {
      $content = this.$el.find( `> ${Accordion.Selector.ACCORDION_PANEL}` );
    }

    if ( !isProgrammaticalClick ) {
      history.replaceState( {}, '', url ); /* 2, 3 */
    }

    this.$trigger.trigger( customEvent, [ this.$trigger, this.$el, $content ] ); /* 4 */

  }

  /**
   * Remove event handlers and references.
   *
   * @todo ¿Close all accordions?
   */
  destroy() {

    this.$el.removeClass( Accordion.ClassName.ACCORDION_ACTIVE );
    this.$trigger.off( Accordion.NAMESPACE );

  }

  /**
   * Initialize a Accordion instance on the current element.
   *
   * @param {jQuery} $collection jQuery collection of each set of tabs on the
   *                             page.
   */
  static init( $collection ) {

    $collection.each( function () {

      const $el = $( this );

      $el.data( Accordion.DATA_KEY, new Accordion( $el ) );

    } );

  }

}



/**
 * Static Properties
 * -----------------------------------------------------------------------------
 */

/**
 * Default plugin options.
 *
 * @type {Object}
 */
Accordion.Defaults = {
  autoFocus: true
};

/**
 * Property name for plugin instance store with jQuery `data()`.
 *
 * @type {String}
 */
Accordion.DATA_KEY = 'und.accordion';

/**
 * Event name space for plugin.
 *
 * Note: Technically, this is two namespaces, `und` and `accordion`. Any event
 * with either
 * @type {String}
 */
Accordion.NAMESPACE = `.${Accordion.DATA_KEY}`;

/**
 * Custom Events
 *
 * @type {Object}
 */
Accordion.Event = {
  OPENED: `opened${Accordion.NAMESPACE}`,
  CLOSED: `closed${Accordion.NAMESPACE}`
};

/**
 * Plugin class names.
 *
 * Notes
 *
 * 1. Container element for a single accordion.
 * 2. Element that triggers open/close of accordion panel.
 * 3. Element that ACCORDION_PANEL points to via `aria-labelled-by`.
 * 4. Element that ACCORDION_TRIGGER will open/close.
 * 5. Element that ACCRODION_TRIGGER points to via `aria-controls`.
 *
 * @type {Object}
 */
Accordion.ClassName = {
  ACCORDION_GROUP:        'accordion-group',
  ACCORDION:              'accordion',                      /* 1 */
  ACCORDION_ACTIVE:       'accordion--active',
  ACCORDION_HEADING:      'accordion__heading',
  ACCORDION_TRIGGER:      'accordion__trigger',             /* 2, 3 */
  ACCORDION_TRIGGER_TEXT: 'accordion__trigger-status-text',
  ACCORDION_PANEL:        'accordion__panel'                /* 4, 5 */
};

/**
 * Plugin CSS Selector values.
 *
 * Based off of `ClassName` values.
 *
 * @type {Object}
 */
Accordion.Selector = {};

Object.keys( Accordion.ClassName ).map( key => Accordion.Selector[ key ] = `.${Accordion.ClassName[ key ]}` );



/**
 * Implementation
 * -----------------------------------------------------------------------------
 */

Accordion.init( $( Accordion.Selector.ACCORDION ) );



module.exports = { Accordion };
