/**
 * UND Core JS
 * -----------------------------------------------------------------------------
 */

// Imports



// Polyfills
require( 'ally.js/style/focus-within' )();



// Run Early
require( './utilities/_fetch-svg' );
require( 'what-input' );
require( './vendor/modernizr/_modernizr' );
require( './vendor-extensions/_respimage' )(); // IE11 and Edge 12 support, replace with Picturefill?
require( 'object-fit-images' )();
// YouTube embeded videos.
// TODO: verify if this should run early and if it needs to be imported by `_leaders-in-action.js`.
require( './utilities/_embed' )();
require( './utilities/_events' );
require( './partials/_header-offset' );
require( './partials/_cookie-banner' );
require( './partials/_campus-alert' );
require( './utilities/_anchor' );
require( './partials/_subnav' );
require( './partials/_topbar' );



// Vendor



// Vendor Extensions



// Partials
require( './partials/_back-to-top' );
require( './partials/_breadcrumb' );
require( './partials/_condensed' );
require( './partials/_direct-edit' );
require( './partials/_form' );
require( './partials/_header' );
require( './partials/_hero' );
require( './partials/_menu' );
require( './partials/_quick-escape' );



// Components
require( './components/_card' );          // Verify if this should have been removed with #277.
require( './components/_college' );       // Similar to `_share.js` for the Home page. Combine?
require( './components/_count-up' );
require( './components/_dropdown' );
require( './components/_event' );         // ¿Abandoned?
require( './components/_minimodal' );
require( './components/_slider' );
require( './components/_stats' );
require( './components/_table' );
require( './components/_tuition-table' );

require( './components/u-accordion-group-trigger' );
require( './components/u-accordion' );
require( './components/u-tabs' );



// Pages
require( './pages/course-finder/_card-slider' );
require( './pages/course-finder/_course-finder' );
require( './pages/home/_majors-and-programs-slider' );
require( './pages/home/_program-search' );
require( './pages/home/_share' );
require( './pages/program-finder/_program-finder' );
require( './pages/program-finder/_compare' );
require( './pages/_leaders-in-action' );
require( './pages/_tuition-calc' );




/**
 * Export Plugins, Utilities, and Constants
 *
 * - These will be available within the `UND` global variable.
 * - Exported for use within third-party code.
 */
module.exports = {
  Constants: require( './utilities/_constants' ),
  Utilities: {
    animateTo:  require( './utilities/_animate-to' ),
    debounce:   require( './utilities/_debounce' ),
    MediaQuery: require( './utilities/_media-query' ).MediaQuery,
    throttle:   require( './vendor/throttle/_throttle' )
  },
  Plugins: {
    Flickity:  require( 'Flickity' ),
    minimodal: require( './vendor/minimodal/_minimodal' )
  }
};
